import useSession from '@/hooks/useSession';
import { Navigate } from 'react-router-dom';
import { FC, PropsWithChildren } from 'react';

export interface ProtectedRouteProps {
  authFlow?: boolean;
}

const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  children,
  authFlow,
}) => {
  const { authenticated } = useSession();

  if (authFlow && authenticated) {
    return <Navigate replace to="/" />;
  }

  if (!authenticated && !authFlow) {
    return <Navigate replace to="/auth/sign-in" />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
