import {
  Stack,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  OutlinedInputProps,
} from '@mui/material';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import style from '@/components/ui/TextInput/style';
import Icon from '@/assets/icons';

type InputType = 'text' | 'password';

export interface TextInputProps extends Omit<OutlinedInputProps, 'error'> {
  value: string;
  label?: string;
  placeholder?: string;
  type?: InputType;
  error?: string;
  touched?: boolean;
  onTextChange?: (text: string) => void;
}

const TextInput: FC<TextInputProps> = ({
  label,
  placeholder,
  onTextChange,
  type,
  value,
  error,
  touched,
  ...props
}) => {
  const [mouseEnter, setMouseEnter] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange?.(e.target.value);
    },
    [onTextChange],
  );

  const handleMouseOver = useCallback(
    (enter: boolean) => () => {
      setMouseEnter(enter);
    },
    [],
  );

  const toggleShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const getAdornmentComponent = () => {
    if (type === 'password') {
      return (
        <IconButton
          onClick={toggleShowPassword}
          onMouseEnter={handleMouseOver(true)}
          onMouseLeave={handleMouseOver(false)}
        >
          {!mouseEnter && !!touched ? (
            !!error ? (
              <Icon.Error />
            ) : (
              <Icon.Checked />
            )
          ) : showPassword ? (
            <Icon.Visible />
          ) : (
            <Icon.Hidden />
          )}
        </IconButton>
      );
    }

    return (
      <Stack width={24}>
        {!!error ? <Icon.Error /> : !!touched ? <Icon.Checked /> : null}
      </Stack>
    );
  };

  return (
    <FormControl style={style.container} error={!!error}>
      <Stack flex={1} gap={1.5}>
        {!!label && (
          <Typography
            variant="body2"
            sx={style.label}
            color={error ? 'error.main' : 'text.primary'}
          >
            {label}
          </Typography>
        )}
        <OutlinedInput
          value={value || ''}
          placeholder={placeholder}
          onChange={handleChange}
          type={type === 'password' && showPassword ? 'text' : type}
          size="small"
          aria-describedby="field-text-input-descriptor"
          endAdornment={
            <InputAdornment position="end">
              {getAdornmentComponent()}
            </InputAdornment>
          }
          {...props}
        />
        <FormHelperText sx={style.error} id="field-text-input-descriptor">
          {error}
        </FormHelperText>
      </Stack>
    </FormControl>
  );
};

export default TextInput;
