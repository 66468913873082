import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
});

export default style;
