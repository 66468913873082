import { ErrorResponse } from '@/utils/error';

export const validateResponseObject = <T extends object>(
  response: T,
  ...requiredKeys: string[]
) => {
  for (const key of requiredKeys) {
    if (!(key in response)) {
      throw new ErrorResponse({
        message: `Missing required key: ${key}`,
        status: 400,
      });
    }
  }
  return true;
};
