import { Outlet } from 'react-router-dom';

import ScreenWrapper from '@/components/ui/ScreenWrapper';

const RootView = () => {
  return (
    <ScreenWrapper>
      <Outlet />
    </ScreenWrapper>
  );
};

export default RootView;
