import { join } from 'lodash';
import qs from 'qs';

export const getPath = (...routes: string[]) => {
  return join(routes, '/');
};

export const getPathQuery = (path: string, query: object) => {
  return join([path, qs.stringify(query)], '?');
};
