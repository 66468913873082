import { Button, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';

export type CTAButtonVariant = 'primary' | 'secondary';

export interface CTAButtonProps {
  label: string;
  onClick?: () => void;
  variant?: CTAButtonVariant;
  disabled?: boolean;
  loading?: boolean;
}

const CTAButton: FC<CTAButtonProps> = ({
  label,
  onClick,
  disabled,
  variant,
  loading,
}) => {
  return (
    <Button
      sx={style.container}
      disableElevation
      disableRipple
      disableFocusRipple
      disableTouchRipple
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color={variant}
    >
      {loading ? (
        <CircularProgress size={20} sx={style.progress} />
      ) : (
        <Typography variant="button" color="primary.contrastText">
          {label}
        </Typography>
      )}
    </Button>
  );
};

export default CTAButton;
