import axios from 'axios';
import env from '@/constants/env';

const Network = axios.create({
  baseURL: env.baseUrl,
});

export const initializeAuthSession = (token: string) => {
  Network.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error),
  );
};

export default Network;
