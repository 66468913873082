import { Box } from '@mui/material';
import Icons, { IconType } from '@/assets/icons';
import { FC } from 'react';

export interface IconProps {
  icon: IconType;
  size?: number;
}

const Icon: FC<IconProps> = ({ icon, size }) => {
  const IconComponent = Icons[icon];

  return <Box component={IconComponent} width={size} height={size} />;
};

export default Icon;
