import { createBrowserRouter, Navigate } from 'react-router-dom';

import RootView from '@/views/RootView';

// auth route views
import AuthView from '@/views/auth';
import SignInView from '@/views/auth/SignIn';
import ForgotPasswordView from '@/views/auth/ForgotPassword';
import ResetPasswordEmail from '@/views/auth/ResetPasswordEmail';
import ResetPasswordView from '@/views/auth/ResetPassword';
import ResetPasswordSuccess from '@/views/auth/ResetPasswordSuccess';
import AdminView from '@/views/admin';
import ProtectedRoute from '@/components/utility/ProtectedRoute';

import { Routes } from '@/constants/routes';
import { getPath } from '@/utils';

const router = createBrowserRouter([
  {
    path: Routes.Home,
    element: <RootView />,
    children: [
      {
        index: true,
        element: <Navigate replace to={Routes.Admin} />,
      },
      {
        path: Routes.Admin,
        element: (
          <ProtectedRoute>
            <AdminView />
          </ProtectedRoute>
        ),
      },
      {
        path: Routes.Auth,
        element: (
          <ProtectedRoute authFlow>
            <AuthView />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate replace to={Routes.SignIn} />,
          },
          {
            path: Routes.SignIn,
            element: <SignInView />,
          },
          {
            path: Routes.ForgotPassword,
            element: <ForgotPasswordView />,
          },
          {
            path: `${Routes.PasswordReset}/:token'`,
            element: <ResetPasswordView />,
          },
        ],
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordEmail),
        element: <ResetPasswordEmail />,
      },
      {
        path: getPath(Routes.Auth, Routes.ResetPasswordSuccess),
        element: <ResetPasswordSuccess />,
      },
    ],
  },
]);

export default router;
