import { ErrorType } from '@/types';

export const ErrorMap = {
  401: ErrorType.Unauthorized,
  403: ErrorType.Forbidden,
  404: ErrorType.NotFound,
  400: ErrorType.BadRequest,
  409: ErrorType.Conflict,
  422: ErrorType.BadRequest,
  500: ErrorType.InternalServerError,
};
