import { createContext } from 'react';

export type SessionContextType = {
  authenticated: boolean | null;
};

const SessionContext = createContext<SessionContextType>({
  authenticated: null,
});

export const SessionProvider = SessionContext.Provider;

export default SessionContext;
