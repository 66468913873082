export const Routes = {
  Home: '/',
  Auth: 'auth',
  SignIn: 'sign-in',
  ResetPasswordEmail: 'reset-password-email',
  ResetPasswordSuccess: 'reset-password-success',
  PasswordReset: 'password-reset',
  ForgotPassword: 'forgot-password',
  Admin: 'admin',
};
