import common from './common.json';
import auth from './auth.json';
import errors from './errors.json';

const deDE = {
  common,
  auth,
  errors,
};

export default deDE;
