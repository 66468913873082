import { Box, BoxProps } from '@mui/material';
import ImageMap, { ImageType } from '@/assets/images';
import { FC } from 'react';

export interface ImageProps extends BoxProps {
  image: ImageType;
}

const Image: FC<ImageProps> = ({ image, ...props }) => {
  return <Box {...props} component="img" src={ImageMap[image]} />;
};

export default Image;
