import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  label: {
    fontWeight: 500,
  },
  container: {
    width: '100%',
  },
  error: {
    margin: 0,
    fontSize: '14px',
  },
});

export default style;
