import { Stack } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

import style from './style';

const ScreenWrapper: FC<PropsWithChildren> = ({ children }) => {
  return <Stack style={style.container}>{children}</Stack>;
};

export default ScreenWrapper;
