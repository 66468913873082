import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ForgotPasswordForm from '@/components/forms/ForgotPasswordForm';
import AuthHeader from '@/components/AuthHeader';
import { ForgotPasswordRequest } from '@/types';
import api from '@/services/api';
import { Routes } from '@/constants/routes';
import { getPath, getPathQuery } from '@/utils';

const ForgotPasswordView = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(Routes.Home);
  };

  const handleSubmit = async (data: ForgotPasswordRequest) => {
    try {
      await api.auth.forgotPassword(data);
    } catch (error) {
      console.error(error);
    } finally {
      navigate(
        getPath(
          '..',
          getPathQuery(Routes.ResetPasswordEmail, { email: data.email }),
        ),
      );
    }
  };

  return (
    <Stack gap={8}>
      <AuthHeader
        title={t('auth:resetPassword.title')}
        subtitle={t('auth:resetPassword.subtitle')}
      />
      <ForgotPasswordForm
        onSubmit={handleSubmit}
        onLoginClick={handleLoginClick}
      />
    </Stack>
  );
};

export default ForgotPasswordView;
