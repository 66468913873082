import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { AuthState, AuthStore } from './types';
import api from '@/services/api';
import { initializeAuthSession } from '@/services/network';
import { ErrorResponse } from '@/utils';

const initialState: AuthState = {
  token: null,
  loading: false,
  error: null,
};

const useAuthStore = create(
  persist<AuthStore>(
    (set) => ({
      ...initialState,
      signIn: async (credentials) => {
        set({ loading: true, error: null });
        try {
          const token = await api.auth.signIn(credentials);
          set({ token: token.accessToken });
        } catch (error) {
          set({ error: (error as ErrorResponse).getType() });
        } finally {
          set({ loading: false });
        }
      },
      signOut: () => {
        set(initialState);
        useAuthStore.persist.clearStorage();
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({ token: state.token }) as AuthStore,
      onRehydrateStorage: (state) => {
        if (state.token) {
          initializeAuthSession(state.token);
        }
      },
    },
  ),
);

export default useAuthStore;
