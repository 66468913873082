import { FC } from 'react';
import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TextField from '@/components/formControl/TextField';
import SubmitButton from '@/components/formControl/SubmitButton';
import LinkButton from '@/components/ui/LinkButton';

import { ForgotPasswordRequest } from '@/types';
import { resetPasswordValidationSchema } from '@/constants/validation';

const initialValues: ForgotPasswordRequest = {
  email: '',
};

export interface ResetPasswordProps {
  onSubmit: (data: ForgotPasswordRequest) => void;
  onLoginClick: () => void;
}

const ForgotPasswordForm: FC<ResetPasswordProps> = ({
  onSubmit,
  onLoginClick,
}) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={resetPasswordValidationSchema}
      validateOnMount={true}
      isInitialValid={false}
    >
      <Stack gap={6}>
        <TextField
          fieldName="email"
          label={t('auth:resetPassword.labels.email')}
        />
        <Stack gap={2}>
          <SubmitButton label={t('auth:resetPassword.actions.recover')} />
          <LinkButton
            size="large"
            text={t('auth:resetPassword.actions.signIn')}
            onClick={onLoginClick}
          />
        </Stack>
      </Stack>
    </Formik>
  );
};

export default ForgotPasswordForm;
