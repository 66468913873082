import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SignInForm from '@/components/forms/SignInForm';
import AuthHeader from '@/components/AuthHeader';
import useAuthStore from '@/store/auth';
import { useNavigate } from 'react-router-dom';
import { getPath } from '@/utils';
import { Routes } from '@/constants/routes';

const SignInView = () => {
  const { t } = useTranslation();

  const { signIn, error } = useAuthStore();

  const navigate = useNavigate();

  const handleForgetPassword = () => {
    navigate(getPath('..', Routes.ForgotPassword));
  };

  return (
    <Stack gap={8}>
      <AuthHeader
        title={t('auth:signIn.title')}
        subtitle={t('auth:signIn.subtitle')}
      />
      <SignInForm
        onSubmit={signIn}
        // @todo(KAN-16): implement all errors translation list
        error={!!error && t(`errors:network.custom.invalidCredentials`)}
        onForgotPasswordClick={handleForgetPassword}
      />
    </Stack>
  );
};

export default SignInView;
