import { ReactComponent as Checked } from './Checked.svg';
import { ReactComponent as Hidden } from './Hidden.svg';
import { ReactComponent as Visible } from './Visible.svg';
import { ReactComponent as Error } from './Error.svg';
import { ReactComponent as EmailLarge } from './EmailLarge.svg';
import { ReactComponent as CheckedLarge } from './CheckedLarge.svg';

const Icon = {
  Checked,
  Hidden,
  Visible,
  Error,
  EmailLarge,
  CheckedLarge,
};

export type IconType = keyof typeof Icon;

export default Icon;
