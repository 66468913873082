import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

import style from './style';
import { SXStyleSheet } from '@/utils';

export interface LinkButtonProps {
  text: string;
  size?: 'small' | 'large';
  onClick?: () => void;
}

const LinkButton: FC<LinkButtonProps> = ({ text, size = 'small', onClick }) => {
  return (
    <Stack>
      <Typography
        sx={SXStyleSheet.merge(style.text, style.textVariants[size])}
        variant="body1"
        color="primary.main"
        textAlign="center"
        onClick={onClick}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default LinkButton;
