import { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

import CTAButton from '@/components/ui/CTAButton';
import Icon from '@/components/ui/Icon';

import type { IconType } from '@/assets/icons';

export interface LargeInfoBlockProps {
  title: string;
  icon: IconType;
  description?: string | ReactNode;
  action: string;
  onActionClick: () => void;
}

const LargeInfoBlock: FC<LargeInfoBlockProps> = ({
  title,
  description,
  action,
  icon,
  onActionClick,
}) => {
  return (
    <Stack spacing={7} alignItems="center" justifyContent="center">
      <Icon icon={icon} />
      <Stack width={678} spacing={6}>
        <Typography textAlign="center" variant="h2" color="text.secondary">
          {title}
        </Typography>
        {!!description && (
          <Typography textAlign="center">{description}</Typography>
        )}
      </Stack>
      <Stack width={360}>
        <CTAButton label={action} onClick={onActionClick} />
      </Stack>
    </Stack>
  );
};

export default LargeInfoBlock;
