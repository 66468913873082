import { Stack, Typography } from '@mui/material';
import { FC } from 'react';

export interface AuthHeaderProps {
  title: string;
  subtitle: string;
}

const AuthHeader: FC<AuthHeaderProps> = ({ title, subtitle }) => {
  return (
    <Stack>
      <Stack alignItems="center" gap={4.5}>
        <Typography variant="h2" color="text.secondary" textAlign="center">
          {title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          {subtitle}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AuthHeader;
