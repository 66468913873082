import { SXStyleSheet } from '@/utils';

const style = SXStyleSheet.create({
  container: {
    height: '44px',
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    [`&.Mui-disabled`]: {
      backgroundColor: (theme) => theme.palette.grey[50],
    },
  },
  progress: {
    color: 'primary.contrastText',
  },
});

export default style;
