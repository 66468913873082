import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import ResetPasswordForm, {
  ResetPasswordFormValues,
} from '@/components/forms/ResetPasswordForm';
import AuthHeader from '@/components/AuthHeader';

import api from '@/services/api';
import { getPath } from '@/utils';
import { Routes } from '@/constants/routes';

const ResetPasswordView = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();

  const email = searchParams?.get('email');

  const handleSubmit = async (data: ResetPasswordFormValues) => {
    if (!email || !token) {
      return;
    }

    try {
      await api.auth.resetPassword({
        ...data,
        email,
        token,
      });
    } catch (error) {
      console.error(error);
    } finally {
      navigate(getPath('..', Routes.ResetPasswordSuccess));
    }
  };

  return (
    <Stack gap={8}>
      <AuthHeader
        title={t('auth:newPassword.title')}
        subtitle={t('auth:newPassword.subtitle')}
      />
      <ResetPasswordForm onSubmit={handleSubmit} />
    </Stack>
  );
};

export default ResetPasswordView;
