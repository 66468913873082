import { Stack, Typography } from '@mui/material';

import LinkButton from '@/components/ui/LinkButton';
import useAuthStore from '@/store/auth';

// @todo(KAN-11): mock admin screen authenticated presentation
//                replace with the designed
const AdminView = () => {
  const { signOut } = useAuthStore();

  return (
    <Stack alignItems="center" flex={1} justifyContent="center">
      <Typography>Authentifiziert!</Typography>
      <LinkButton text="Abmelden" onClick={signOut} />
    </Stack>
  );
};

export default AdminView;
