import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Image from '@/components/ui/Image';
import ImageCard from '@/components/ImageCard';
import CopyrightNotice from '@/components/CopyrightNotice';

const AuthView = () => {
  const { t } = useTranslation();

  return (
    <Stack flex={1} flexDirection="row">
      <Stack flex={1}>
        <Stack
          flex={1}
          pt="25px"
          alignItems="center"
          justifyContent="center"
          gap="32px"
        >
          <Image image="Logo" width="302px" />
          <Stack width="363px">
            <Outlet />
          </Stack>
        </Stack>
        <CopyrightNotice />
      </Stack>
      <ImageCard
        image="Insurance"
        title={t('auth:side.title')}
        subtitle={t('auth:side.subtitle')}
      />
    </Stack>
  );
};

export default AuthView;
