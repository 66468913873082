import { createTheme } from '@mui/material';
import { MuiOutlinedInputStyle } from './input';

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: '"Inter", sans-serif',
    h1: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      fontOpticalSizing: 'auto',
    },
    h2: {
      fontWeight: 600,
      fontSize: '28px',
      lineHeight: '38px',
      fontOpticalSizing: 'auto',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      fontOpticalSizing: 'auto',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      fontOpticalSizing: 'auto',
    },
    button: {
      fontSize: '16px',
      fontWeight: 600,
      textTransform: 'none',
      fontOpticalSizing: 'auto',
    },
  },
  palette: {
    primary: {
      main: '#036A8B',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F59418',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#475467',
      secondary: '#101828',
    },
    grey: {
      50: '#EEEEEE',
      100: '#D0D5DD',
      200: '#B3B3B5',
      800: '#475467',
      900: '#101828',
    },
    error: {
      main: '#F04438',
    },
  },
  components: {
    MuiOutlinedInput: MuiOutlinedInputStyle,
  },
});

export default theme;
