import * as yup from 'yup';

export const signInValidationSchema = yup.object().shape({
  email: yup.string().email('email.notMatch').required('email.required'),
  password: yup.string().required('password.required'),
});

export const resetPasswordValidationSchema = yup.object().shape({
  email: yup.string().email('email.notMatch').required('email.required'),
});

export const newPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('password.required'),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref('password')], 'password.required')
    .required('password.required'),
});
