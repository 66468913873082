import {
  inputBaseClasses,
  inputClasses,
  outlinedInputClasses,
} from '@mui/material';

export const MuiOutlinedInputStyle = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      height: '44px',
      paddingRight: '6px',
      [`&.${inputClasses.focused} .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#D0D5DD',
        borderWidth: 1,
      },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: '#D0D5DD',
      },
      [`& .${inputBaseClasses.input}`]: {
        fontFamily: '"Inter", sans-serif',
        fontSize: '16px',
        color: '#101828',
        '::placeholder': {
          color: '#667085',
          opacity: 1,
        },
      },
      [`&.${inputClasses.error}`]: {
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: '#F04438',
        },
        [`& .${inputBaseClasses.input}`]: {
          color: '#F04438', // Change text color for error state
          '::placeholder': {
            color: '#F04438', // Change placeholder color for error state if needed
            opacity: 1,
          },
        },
      },
    },
    notchedOutline: {
      borderWidth: 1,
      borderColor: '#D0D5DD',
    },
  },
};
