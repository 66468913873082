import { Formik } from 'formik';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';

import TextField from '@/components/formControl/TextField';
import SubmitButton from '@/components/formControl/SubmitButton';

import { ResetPasswordRequest } from '@/types';
import { newPasswordValidationSchema } from '@/constants/validation';

export type ResetPasswordFormValues = Pick<
  ResetPasswordRequest,
  'password' | 'password_confirmation'
>;

const initialValues: ResetPasswordFormValues = {
  password: '',
  password_confirmation: '',
};

export interface ResetPasswordFormProps {
  onSubmit: (values: ResetPasswordFormValues) => Promise<void>;
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={newPasswordValidationSchema}
      validateOnMount={true}
      isInitialValid={false}
    >
      <Stack gap={6}>
        <Stack gap={5}>
          <TextField
            fieldName="password"
            label={t('auth:newPassword.labels.newPassword')}
            type="password"
          />
          <TextField
            fieldName="password_confirmation"
            label={t('auth:newPassword.labels.confirmPassword')}
            type="password"
          />
        </Stack>
        <SubmitButton label={t('auth:newPassword.actions.confirm')} />
      </Stack>
    </Formik>
  );
};

export default ResetPasswordForm;
