import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LargeInfoBlock from '@/components/LargeInfoBlock';

const ResetPasswordSuccess = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const navigateHome = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  return (
    <Stack flex={1} alignItems="center" justifyContent="center">
      <LargeInfoBlock
        icon="CheckedLarge"
        title={t('auth:newPasswordSuccess.title')}
        action={t('auth:newPasswordSuccess.action')}
        onActionClick={navigateHome}
      />
    </Stack>
  );
};

export default ResetPasswordSuccess;
